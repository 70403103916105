import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link } from "gatsby"

const PolicyPage = () => (
  <Layout>
    <Seo title="Home" />
    <p>
      <Link to="/">Home</Link>
    </p>
    <h2>Privacy Policy</h2>
    Shooters Diary 2 (SD2)
    <p>
      If you are a subscriber to the competition feature, following data for the
      competiton are stored in a database.
      <ul>
        <li>Name of the competition</li>
        <li>Your scores in the competition</li>
      </ul>
    </p>
    <p>
      If you are not a subscriber to competition feature SD2 does not
      store/collect/send/manage any personal data of any kind. All materials
      created with Shooters Diary are solely yours (the user).
    </p>
    <p>
      <Link to="/">Go back</Link>
    </p>
  </Layout>
)

export default PolicyPage
